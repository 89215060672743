import React from 'react';

const styles = {
    success: {
        border: "1px solid green",
        padding: "10px",
        color: "green"
    },
    error: {
        border: "1px solid red",
        padding: "10px",
        color: "red"
    },
    info: {
        border: "1px solid orange",
        padding: "10px",
        color: "orange"
    }
}

export const Alert = ({type, text}) => {

    return (
        <>
        <p style={styles[type]}>{text}</p>
        </>
    )
}