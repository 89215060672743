import { DEPLOY_RUSSIA, DEPLOY_USA } from "./en_strings";
import { DEPLOY_COUNTRY } from "./urls";

export function deployedUSA() {
  return DEPLOY_COUNTRY === DEPLOY_USA;
}

export function deployedRussia() {
  return DEPLOY_COUNTRY === DEPLOY_RUSSIA;
}
