import { Navigate, Outlet, useLocation } from "react-router-dom";


const PrivateRoutes = () => {
    const location = useLocation();
    const services_token = localStorage.getItem('services_access_token')

    return (
        services_token ? <Outlet /> : <Navigate to="/login" state={{from : location}} replace />
    )
}


export default PrivateRoutes;