export const SERVICES_AUTH_ENDPOINT = "/auth/login/"
export const SERVICES_REFRESH_ACCESS_TOKEN_ENDPOINT = "/auth/refresh/"
export const SERVICES_PLANS_LIST_ENDPOINT = "/tms/plans/"
export const SERVICES_PLAN_LIST_ENDPOINT = "/tms/plans/"

export const SERVICES_TREATMENT_LIST_ENDPOINT = "/tms/treatments/"
export const SERVICES_PLAN_BY_TREATMENT_LIST_ENDPOINT = "/tms/plans_by_treatment/"

export const TAM_LOGIN_ENDPOINT = "/v1/auth/login/"
export const TAM_PLANS_LIST_ENDPOINT = "/v2/staff/job/"
export const TAM_PLAN_VERSION_ENDPOINT = (jobId) => `/v2/job/${jobId}/tplans/versions/files/`
export const TAM_UPDATE_PLAN_ENDPOINT = (jobId) => `/v1/staff/job/${jobId}/tplan_modeling_json_data`

export const TAM_CHECK_TASK_STATUS_ENDPOINT = (jobId) => `/v1/staff/job/status/${jobId}/`
export const TAM_DELETE_GUMS_AND_BONES_ENDPOINT = (jobId) => `/v1/staff/tam/modeling/remove/deformation/models/${jobId}/`

export const UPLOAD_FILE = "/fms/files/upload/"
export const UPLOAD_FILE_STATUS_CHECK = (action_id) => `/fms/file_actions/${action_id}/`
export const PUBLIC_FILE = "/fms/files/upload_callback/"
export const GET_FILES_BY_TREATMENT_ID = (treatment_id) => `/fms/files/?treatment=${treatment_id}`
export const DOWNLOAD_FILE = "/fms/files/download/"

export const GET_REGIONS = "/wfms/treatments/regions/"
export const GET_TREATMENTS_TYPES = "/tms/treatment_types/"
export const GET_WORKFLOW_TYPES = "/wfms/workflows/"
export const CREATE_TREATMENT = "/wfms/treatments/"
export const GET_TREATMENT_BY_PATIENT_ID = (patient_id) => `/wfms/treatments/?lk_patient_id=${patient_id}`
export const GET_FILTERED_TREATMENTS = "/wfms/treatments/?"
export const GET_TREATMENT_BY_TREATMENT_ID = (treatment_id) => `/wfms/treatments/${treatment_id}/`

export const CT_WORKFLOW_RUN = (treatment_id) => `/wfms/treatments/${treatment_id}/run/`