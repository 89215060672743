import React from 'react';
import { Navigate } from 'react-router';
import { useSearchParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setToken } from '../../redux/tokenSlice';

function SecurePage(props) {

    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch()

    const token = searchParams.get("token");
    const service_token = searchParams.get("services_token");
    const treatment_id = searchParams.get("treatment");
    localStorage.setItem('tam_access_token', token)
    localStorage.setItem('services_access_token', service_token);
    localStorage.setItem('treatment_id', treatment_id);
    dispatch(setToken({tam_token: token, services_token: service_token, treatment_id: treatment_id}))

    if (token && service_token) {
        return <Navigate to={`/treatment/${treatment_id}`} />
    } else {
        return <Navigate to="/login" />
    }
}


export default SecurePage;