import { deployedUSA } from "../../constants/utils";

const dicomZipFile = 'dicom.zip';
export const generateHeaders = (action_id, fileName) => {
    if (deployedUSA()) {
      return (
        {
          "Content-Type": fileName === dicomZipFile ? "application/octet-stream" : "application/gzip",
          "x-goog-meta-action_id": action_id,
        }
      )
    } else {
      return (
        {
          "Content-Type": fileName === dicomZipFile ? "application/octet-stream" : "application/gzip",
          "x-amz-meta-action_id": action_id,
        }
      )
    }
};