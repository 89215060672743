import React from "react";
import { Input } from "antd";

import { SEARCH_TREATMENT_BY_PATIENT_ID } from "../../constants/en_strings";

import "./inputs.css";

const { Search } = Input;

function CustonSearch({ getTreatmentByPatientId }) {
  return (
    <Search
      placeholder={SEARCH_TREATMENT_BY_PATIENT_ID}
      onSearch={getTreatmentByPatientId}
      enterButton="Search"
      allowClear
      style={{
        backgroundColor: "#fff",
        boxShadow: "none",
      }}
      // fillRule=""
    />
  );
}

export default CustonSearch;
