import React, { useState, useEffect } from 'react';
import { Layout, Button, Row, Col, Form, Tooltip } from 'antd';
import LinearProgress from '@mui/material/LinearProgress';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import toast from 'react-hot-toast';

import SideBar from '../../components/sidebar/sidebar';
import {
    GET_TREATMENT_BY_TREATMENT_ID,
    GET_FILES_BY_TREATMENT_ID,
    DOWNLOAD_FILE,
    CT_WORKFLOW_RUN,
    UPLOAD_FILE,
    PUBLIC_FILE
} from '../../constants/endpoints';
import { SERVICES_URL } from '../../constants/urls';
import StickyHeadTable from '../../components/table/table_mui';
import {
    DOWNLOAD,
    REUPLOAD,
    PATIENT_ID,
    WORKFLOW_TYPE,
    TREATMENT_TYPE,
    CREATION_DATE,
    MODIFIED,
    STATUS,
    CURRENT_STEP,
    AUTHOR,
    RUN,
    SEGMENTATION_STARTED,
    FILES,
    TREATMENT_TYPES,
    WORKFLOW_TYPES,
    REGIONS,
    REGION,
    COPY_FILE_ID,
    FILE_REUPLOADED,
    FILE_WAS_REMOVED
} from '../../constants/en_strings';
import UploadFileModal from '../../components/modal/modal';
import { Status } from '../../components/render_status/status';
import { Alert } from '../../components/alert/alert';
import { generateHeaders } from '../../components/utils/generate_headers';

const { Content } = Layout;

const dicomZipFile = 'dicom.zip';
const niftiFileName = 'teeth_separated.nii.gz';

const columns = [
    {
        id: 'name',
        label: 'Name',
        minWidth: 60,
        align: 'left'
    },
    {
        id: 'path',
        label: 'Path',
        minWidth: 60,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'file_id',
        label: 'File ID',
        minWidth: 60,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'action',
        label: 'Action',
        minWidth: 80,
        align: 'left'
    }
];

function createData(name, path, file_id, action) {
    return { name, path, file_id, action };
}


const Treatment = () => {
    const { state } = useLocation();
    const { treatmentId } = useParams();
    const [accessToken, setTMSAccessToken] = useState(localStorage.getItem('services_access_token'));
    const [treatment, setTreatment] = useState({});
    const [files, setFiles] = useState([]);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [fileExtension, setFileExtension] = useState("");
    const [error, setError] = useState(null);
    const [disable, setDisable] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [running, setRunning] = useState(false);

    const errorNotify = (text) => toast.error(text);
    const successNotify = (text) => toast.success(text);

    const headers =
    {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
        }
    }

    const treatment_id = state || treatmentId.slice(1);

    const fetchTreatmentInfo = () => {
        axios(`${SERVICES_URL}${GET_TREATMENT_BY_TREATMENT_ID(treatment_id)}`, headers)
            .then((res) => setTreatment(res.data))
            .catch(err => {
                errorNotify(err.message);
            })
    }
    
    const fetchFiles = () => {
        axios(`${SERVICES_URL}${GET_FILES_BY_TREATMENT_ID(treatment_id)}`, headers)
            .then(res => setFiles(res.data))
            .catch(err => {
                errorNotify(err.message);
            })
    }

    useEffect(() => {
        fetchTreatmentInfo();
    }, [])

    useEffect(() => {
        fetchFiles();
    }, [])

    const downloadFile = (id) => {
        axios.post(`${SERVICES_URL}${DOWNLOAD_FILE}`, {
            files: [
                id
            ]
        }, headers)
            .then(res => res.data)
            .then(data => {
                const name = data[id].file.name;
                const url = Object.values(data)[0].url;
                const link = document.createElement('a');
                link.href = url;
                link.download = name;
                link.click();
            }).catch(err => {
                setError(err);
                errorNotify(err.message);
            })
    }

    const rows = files &&
        files.map((f) => {
            return createData(f.name, f.path, f.id, f.publish
                ? <div>
                    <Button style={{ marginRight: "4px" }} onClick={() => downloadFile(f.id)}>{DOWNLOAD}</Button>
                    {(treatment.status === 'draft' || treatment.status === 'done')
                        && (f.name === dicomZipFile || f.name === niftiFileName) ?
                        <Button onClick={() => reuploadFile(f.name)}>{REUPLOAD}</Button> : null
                    }
                  </div>
                : <div>
                    {(treatment.status === 'draft' || treatment.status === 'done')
                        && (f.name === dicomZipFile) ?
                        <div>
                          <Tooltip title={FILE_WAS_REMOVED}>
                            <Button style={{ marginRight: "4px" }} disabled>{DOWNLOAD}</Button>
                          </Tooltip>
                          <Button onClick={() => reuploadFile(f.name)}>{REUPLOAD}</Button>
                        </div>  : null
                    }
                 </div>
            )
        });

    const reuploadFile = (name) => {
        setFileExtension(name.split(/\.(?=[^\.]+$)/)[1])
        setFileName(name)
        setIsModalOpen(true);
    }

    const uploadFile = async (files) => {
        setDisableSubmit(false);
        setFile(files[0]);
    }

    const sendFile = async () => {
        try {
            setDisableSubmit(true);
            setError(null);
            setLoading(true);
            const upload_files = [];
            const name = fileName === dicomZipFile ? dicomZipFile : niftiFileName;
            const path = fileName === dicomZipFile ? "dicom" : "nifti";
            upload_files.push({ name: name, prefix: "", path: path, treatment_id: treatment_id });
            const getFileUrlRes = await axios.post(`${SERVICES_URL}${UPLOAD_FILE}`, { files: upload_files }, headers);
            const url = getFileUrlRes.data[0].url;
            const action_id = getFileUrlRes.data[0].id;
            let blob = new Blob([file], { type: file.type });
            const _headers = generateHeaders(action_id, fileName)
            const result = await axios.put(url, blob, { headers: _headers })
            if (result.status === 200) {
                const publicFile = await axios.post(`${SERVICES_URL}${PUBLIC_FILE}`, {action_id: action_id }, headers)
                if (publicFile.status === 200) {
                    setLoading(false);
                    setFileUploaded(true);
                    setTimeout(() => {
                        onModalClose();
                        fetchFiles();
                    }, 3000);
                    successNotify(FILE_REUPLOADED);
                }
            }
        }
        catch (err) {
            errorNotify(err.message)
            setDisableSubmit(true);
            setFile(null);
            setLoading(false);
            setError(err);
        }
    }

    const onModalClose = () => {
        setFile(null);
        setFileUploaded(false);
        setIsModalOpen(false);
    }

    const ctWorkflowRun = () => {
        setRunning(true);
        setDisable(true);
        axios.post(`${SERVICES_URL}${CT_WORKFLOW_RUN(treatment_id)}`, {}, headers)
            .then(res => {
                if (res.status === 202) {
                    successNotify(SEGMENTATION_STARTED);
                }
            })
            .then(() => {
                setTimeout(() => {
                    setRunning(false);
                    fetchTreatmentInfo();
                }, 4000)
            })
            .catch(err => {
                errorNotify(err.message)
                setError(err)
            })
    }

    return (
        <>
            <SideBar />
            <Content style={{ margin: "40px" }}>
                <h3>{`TREATMENT INFO - ${treatment_id}`}</h3>
                {error ? <p style={{ border: "1px solid red", padding: "10px", color: "red" }}>{error.message}</p> : null}
                <Row style={{ margin: "20px 0" }}>
                    <Col span={7} style={{ backgroundColor: "#fff", padding: "40px", marginRight: "30px" }}>
                        <div style={{ lineHeight: "32px" }}>
                            <div style={{ fontWeight: "700" }}>{PATIENT_ID}: <span style={{ float: "right", fontSize: "16px" }}>{treatment.lk_patient_id}</span></div>
                            {
                                treatment.region && treatment.region !== null
                                    ? (
                                        <div>
                                            {REGION}: <span style={{ float: "right" }}>{REGIONS[treatment.region]}</span>
                                        </div>
                                    ) : null
                            }
                            <div>{TREATMENT_TYPE}: <span style={{ float: "right" }}>{TREATMENT_TYPES[treatment.treatment_type]}</span></div>
                            <div>{WORKFLOW_TYPE}: <span style={{ float: "right" }}>{WORKFLOW_TYPES[treatment.workflow]}</span></div>
                            <div style={{marginBottom: "6px"}}>{STATUS}: <Status status={treatment.status}/></div>
                            <div>{CURRENT_STEP}: <span style={{ float: "right" }}>{treatment.step}</span></div>
                            <div>{AUTHOR}: <span style={{ float: "right" }}>{treatment.author}</span></div>
                            <div>{CREATION_DATE}: <span style={{ float: "right" }}>{moment(treatment.created).format('lll')}</span></div>
                            <div>{MODIFIED}: <span style={{ float: "right" }}>{moment(treatment.modified).format('lll')}</span></div>
                        </div>
                        <br />
                        <div style={{ display: "block" }}>
                            {running ? <Form.Item><LinearProgress /></Form.Item> : null}
                            {treatment.status === 'draft' && !disable ? <Button disabled={disable} onClick={ctWorkflowRun} style={{ marginBottom: "10px", display: "block" }}>{RUN}</Button> : null}
                            {treatment.error && treatment.error !== null ? <Alert type="error" text={treatment.error} /> : null}
                        </div>
                    </Col>
                    <UploadFileModal
                        open={isModalOpen}
                        onCancel={() => onModalClose()}
                        onOk={() => sendFile()}
                        fileName={fileName}
                        fileExtension={fileExtension}
                        file={file}
                        uploadFile={uploadFile}
                        error={error}
                        loading={loading}
                        fileUploaded={fileUploaded}
                        disableSubmit={disableSubmit}
                    />
                    <Col span={16}>
                        <h3 style={{ backgroundColor: "#fff", textAlign: "center", marginBottom: "0", borderBottom: "1px solid #e0e0e1", padding: "10px" }}>{FILES}</h3>
                        <StickyHeadTable copyTitle={COPY_FILE_ID} rows={rows} columns={columns} />
                    </Col>
                </Row>
            </Content>
        </>
    )
}


export default Treatment;