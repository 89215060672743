import { DatePicker } from 'antd';


const { RangePicker } = DatePicker;

const DataPicker = ({ getTreatmentDate }) => {

  return (
    <>
      <RangePicker allowEmpty={[false, true]} onCalendarChange={getTreatmentDate} />
    </>
  );
};
export default DataPicker;