import React from 'react';
import { Button } from 'antd';
import { NavLink } from 'react-router-dom';


function CustomButton({path, title}) {

    return (
        <>
            <NavLink to={path}><Button className='fadeIn fourth' style={{width: "180px", height: "50px", margin: "10px 5px"}} ghost>{title}</Button></NavLink>
        </>
    )
}


export default CustomButton;