import { PageHeader } from "antd";
import { useNavigate } from "react-router-dom";

export const Header = (props) => {
  const history = useNavigate();

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={props.title}
        subTitle={props.subtitle ? props.subtitle : ""}
        style={{ justifyContent: "left", display: "flex" }}
      />
    </>
  );
};
