import React, { useEffect, useState } from "react";
import { Col, Layout, Row } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import toast from "react-hot-toast";

import {
  GET_FILTERED_TREATMENTS,
  CREATE_TREATMENT,
} from "../../constants/endpoints";
import { SERVICES_URL } from "../../constants/urls";
import DataPicker from "../../components/datapicker/data_picker";
import CustonSearch from "../../components/inputs/search";
import StickyHeadTable from "../../components/table/table_mui";
import SideBar from "../../components/sidebar/sidebar";
import {
  NOTHING_FOUND,
  TREATMENT_TYPES,
  WORKFLOW_TYPES,
  COPY_TREATMENT_ID,
} from "../../constants/en_strings";
import { Status } from "../../components/render_status/status";

const { Content } = Layout;

const columns = [
  {
    id: "patient_tag",
    label: "Patient ID",
    minWidth: 100,
    align: "center",
  },
  {
    id: "treatment_id",
    label: "Treatment ID",
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "type",
    label: "Treatment Type",
    minWidth: 140,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "workflow",
    label: "Workflow",
    minWidth: 80,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "status",
    label: "Status",
    minWidth: 80,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "step",
    label: "Step",
    minWidth: 140,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "owner",
    label: "Owner",
    minWidth: 120,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "created",
    label: "Created",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "updated",
    label: "Updated",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

function createData(
  patient_tag,
  treatment_id,
  type,
  workflow,
  status,
  step,
  owner,
  created,
  updated
) {
  return {
    patient_tag,
    treatment_id,
    type,
    workflow,
    status,
    step,
    owner,
    created,
    updated,
  };
}

function TreatmentsList() {
  const navigate = useNavigate();
  const [accessToken, setTMSAccessToken] = useState(
    localStorage.getItem("services_access_token")
  );
  const [error, setError] = useState(null);
  const [treatments, setTreatments] = useState([]);
  const [date, setDate] = useState({
    start: null,
    end: null,
  });
  const errorNotify = (text) => toast.error(text);
  const infoNotify = (text) => toast(text);

  const headers = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  useEffect(() => {
    getTreatments();
  }, []);

  const rows =
    treatments &&
    treatments.map((t) => {
      return createData(
        t.lk_patient_id,
        t.id,
        TREATMENT_TYPES[t.treatment_type],
        WORKFLOW_TYPES[t.workflow],
        <Status status={t.status} />,
        t.step,
        t.author,
        moment(t.created).format("lll"),
        moment(t.modified).format("lll")
      );
    });

  const redirectToTreatment = (treatment_id) => {
    navigate(`/workflow/treatments/:${treatment_id}`);
  };

  const getTreatments = () => {
    axios(`${SERVICES_URL}${CREATE_TREATMENT}`, headers)
      .then((res) => setTreatments(res.data))
      .catch((err) => {
        errorNotify(err.message);
      });
  };

  const getTreatmentDate = (v) => {
    if (v !== null && v[0] !== null && v[1] !== null) {
      setDate({
        start: moment(v[0]._d).format("YYYY-MM-DD"),
        end: v[1] ? moment(v[1]._d).format("YYYY-MM-DD") : null,
      });
    } else if (v !== null && v[0] !== null) {
      setDate({
        start: moment(v[0]._d).format("YYYY-MM-DD"),
        end: null,
      });
    } else {
      setDate({
        start: null,
        end: null,
      });
    }
  };

  const getTreatmentByPatientId = (value) => {
    const { start, end } = date;
    const p = !!value ? `lk_patient_id=${value}` : false;
    const s = !!start ? `from=${start}` : false;
    const t = !!end ? `to=${end}` : false;
    let url = GET_FILTERED_TREATMENTS;
    if (p && p !== null) {
      url += `${p}`;
    }
    if (s) {
      url += p ? `&${s}` : `${s}`;
    }
    if (t) {
      url += `&${t}`;
    }
    setError(null);
    if (value !== "" || start !== null || end !== null) {
      axios(`${SERVICES_URL}${url}`, headers)
        .then((res) => {
          if (res.data < 1) {
            errorNotify(NOTHING_FOUND);
            setTreatments([]);
          } else {
            setTreatments(res.data);
          }
        })
        .catch((err) => {
          setError(err);
          errorNotify(err.message);
        });
    } else {
      infoNotify("Please enter the patient Id or the date");
    }
  };

  return (
    <>
      <SideBar />
      <Content>
        <Col span={22} offset={1}>
          <Row style={{ margin: "20px 0" }}>
            <Col span={6}>
              <DataPicker getTreatmentDate={getTreatmentDate} />
            </Col>
            <Col span={17} offset={1}>
              <CustonSearch getTreatmentByPatientId={getTreatmentByPatientId} />
            </Col>
          </Row>
          <StickyHeadTable
            treatmentList={true}
            copyTitle={COPY_TREATMENT_ID}
            columns={columns}
            rows={rows}
            redirectToTreatment={redirectToTreatment}
          />
        </Col>
      </Content>
    </>
  );
}

export default TreatmentsList;
