import React from 'react';
import { Spin } from 'antd';

import { STATUSES } from '../../constants/en_strings';

const styles = {
    draft: {
        "textAlign": "center",
        "minWidth" : "60px",
        "padding": "2px 8px",
        "border": "1px solid #e0e0e0",
        "background": "#faf8f8",
        "float": "right"
    },
    in_progress: {
        "textAlign": "center",
        "minWidth" : "60px",
        "padding": "2px 8px",
        "border": "1px solid #ffe58f",
        "background": "#fffbe6",
        "float": "right"
    },
    failed: {
        "textAlign": "center",
        "minWidth" : "60px",
        "padding": "2px 8px",
        "border": "1px solid #ffccc7",
        "background": "#fff2f0",
        "float": "right"
    },
    done: {
        "textAlign": "center",
        "minWidth" : "60px",
        "padding": "2px 8px",
        "border": "1px solid #b7eb8f",
        "background": "#f6ffed",
        "float": "right"
    }
}

export const Status = ({status}) => {

    return (
        <>
        {status === 'in_progress' ? <Spin /> : null}
        <span style={styles[status]}>{STATUSES[status]}</span>
        </>
    )
}