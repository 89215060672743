import { useState } from 'react';

export default function useAccessToken() {

  const getToken = () => {
    const tokenString = localStorage.getItem('services_access_token');
    return tokenString
  };

  const saveToken = (TAMuserTokenData, TMSuserTokenData) => {
    if (TAMuserTokenData && TMSuserTokenData) {
      localStorage.setItem('services_access_token', TMSuserTokenData.data.access);
      localStorage.setItem('services_refresh_token', TMSuserTokenData.data.refresh);
      localStorage.setItem('tam_access_token', TAMuserTokenData.data.token);
      setToken(TMSuserTokenData.data.access);
      setTamToken(TAMuserTokenData.data.token)
    } else {
      localStorage.removeItem('services_access_token');
      localStorage.removeItem('tam_access_token');
      setToken();
    }
  };

  const [token, setToken] = useState(getToken());
  const [_tam_token, setTamToken] = useState(getToken());

  return {
    setToken: saveToken,
    _tam_token,
    token,
  }
}