export const header_style = {
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'baseline',
    'color': 'white',
    'fontFamily': "'Montserrat', sans-serif",
    'flexDirection': 'row',
}

export const footer_style = {
    'display': 'flex',
    'justifyContent': 'flex-end',
    'background': '#001529',
    'color': 'white'
}

export const logout_button_wrapper_style = {
    'display':'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'height': '100%'
}

export const logout_button_style = {
    'background': '#3498db',
    'borderRadius': '8px',
    'fontFamily': "Montserrat', sans-serif",
    'color': '#ffffff',
    // 'font-size': '20px',
    // 'padding': '10px 20px 10px 20px',
    'textDecoration': 'none',
    'width': '60px',
    'height': '40px',
    'textAlign': 'center',
    // 'height': '5px'
}