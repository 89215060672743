import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import axios from "axios";
import { diff } from 'deep-object-diff';
import { isNull } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import 'antd/dist/antd.css';

import { Plans } from './plans'
import { Header } from '../../components/header';
import {
  SERVICES_PLAN_LIST_ENDPOINT,
  SERVICES_PLANS_LIST_ENDPOINT,
  TAM_UPDATE_PLAN_ENDPOINT,
  SERVICES_REFRESH_ACCESS_TOKEN_ENDPOINT,
  TAM_DELETE_GUMS_AND_BONES_ENDPOINT,
  TAM_CHECK_TASK_STATUS_ENDPOINT,
} from "../../constants/endpoints";
import { SERVICES_URL, TAM_URL } from "../../constants/urls";
import { setToken, clearToken } from '../../redux/tokenSlice';


function transformPlan(plan) {
  const data = JSON.parse(JSON.stringify(plan))
  data['parent_plan'] = plan.id
  data['approved'] = false
  data['published'] = false
  data['is_modification'] = true
  data['active'] = true
  data.author['role'] = 'technician'
  return data
}

function TechnicianPage(props) {

  const [plansDWH, setPlansDWH] = useState({})
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isApproved, setApproved] = useState(false)
  const { treatmentId } = useParams()
  const [activePlan, setActivePlan] = useState()
  const dispatch = useDispatch()
  let { tam_token, services_token, treatment_id } = useSelector((state) => state)
  if (!tam_token.length || !services_token.length || !treatment_id.length) {
    const storage_services_token = localStorage.getItem('services_access_token')
    const storage_tam_token = localStorage.getItem('tam_access_token')
    const storage_treatment_id = localStorage.getItem('treatment_id') ? localStorage.getItem('treatment_id') : treatmentId
    dispatch(setToken({tam_token: storage_tam_token, services_token: storage_services_token, treatment_id: storage_treatment_id}))
  }
  const refresh = localStorage.getItem('services_refresh_token');
  const _tam_token = tam_token ? tam_token :  localStorage.getItem('tam_access_token')
  const _services_token = services_token ? services_token :  localStorage.getItem('services_access_token')
  const _treatment_id = treatment_id ? treatment_id :  localStorage.getItem('treatment_id')

  const refreshToken = async () => {
    const token = await axios.post(`${SERVICES_URL}${SERVICES_REFRESH_ACCESS_TOKEN_ENDPOINT}`, {refresh})
    dispatch(setToken({services_token: token.data.access}))
    localStorage.setItem('services_access_token', token.data.access);
  }

  const calcDiffs = (plans_dct) => {
    let plans = Object.assign({}, plans_dct);
    for (const [key, value] of Object.entries(plans)) {
      const parent_plan = plans[value['parent_plan']];
      if (!isNull(value['parent_plan'])) {
        plans[key]['modeling_data_diff'] = diff(parent_plan['modeling_data_dict'], value['modeling_data_dict'])
      }
    }
    setPlansDWH(plans)
  }

  const fetchModelingData = async (plans, plans_dct, auth_token) => {
    const headers = {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${auth_token}`,
      }
    }
    const data = plans.data;
    for (var plan of data) {
      const plan_id = plan.id;
      const modeling_data = await axios.get(`${SERVICES_URL}${SERVICES_PLANS_LIST_ENDPOINT}${plan_id}/modeling_data/`, headers)
      plans_dct[plan_id]['modeling_data_dict'] = modeling_data.data.data
    }
    calcDiffs(plans_dct)
  }

  const fetchPlansTMS = async () => {
    console.log('fetchin tms plans')
    const headers = {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${_services_token}`,
      }
    }
    try {
      const response = await axios.get(`${SERVICES_URL}${SERVICES_PLANS_LIST_ENDPOINT}?treatment=${_treatment_id}`, headers)
      const data = response.data
      const plans_dct = Object.assign({}, ...data.map((x) => ({ [x.id]: x })))

      const plans_dct_sorted = Object
      .entries(plans_dct)
      .sort((a, b) => Date.parse(b[1].created) - Date.parse(a[1].created))
      .reduce((_sortedObj, [k, v]) => ({
        ..._sortedObj,
        [k]: v
      }), {})

      const plans_dct_sorted_revert = Object
      .entries(plans_dct)
      .sort((a, b) => Date.parse(a[1].created) - Date.parse(b[1].created))
      .reduce((_sortedObj, [k, v]) => ({
        ..._sortedObj,
        [k]: v
      }), {})

      const plans_dct_notmod = Object.entries(plans_dct_sorted_revert).filter(x => !x[1].is_modification)
      const plans_dct_mod = Object.entries(plans_dct_sorted_revert).filter(x => x[1].is_modification)

      const plans_dct_indexed = plans_dct_notmod.map(function(element, index) {
        element[1]['index'] = index+1;
        element[1]['modifications'] = [];
        return element;
      })
      .reduce((_sortedObj, [k, v]) => ({
        ..._sortedObj,
        [k]: v
      }), {})

      for (const plan of plans_dct_mod) {
        var dct = {}
        dct[plan[0]] = plan[1]
        plans_dct_indexed[plan[1]['parent_plan']]['modifications'].push(plan[1])
      }

      for (let [index, plan] of Object.entries(plans_dct_indexed)) {
        if (plan['modifications'].length > 0) {
          let modifications = plan['modifications'].sort((a, b) => Date.parse(a.created) - Date.parse(b.created))
          let dct = {}
          let modification_cnt = plan['index']
          for (let index in modifications) {
            modification_cnt = Number((modification_cnt + 0.1).toFixed(1))
            modifications[index]['index'] = modification_cnt
            dct[modifications[index]['id']] = modifications[index]
            plans_dct_sorted[modifications[index]['id']]['index'] = modification_cnt
          }
          plan['modifications'] = dct
        }
      }

      setPlansDWH(plans_dct_sorted)
      setApproved(Object.values(data).map(plan => plan.approved).includes(true))
      setActivePlan(Object.values(data).map(plan => plan.active).includes(true))
      setLoading(false)
      fetchModelingData(response, plans_dct_sorted, _services_token)
    } catch (err) {
      setError(true)
      console.log(err.message);
      if (err.response.status === 401) {
        dispatch(clearToken())
        refreshToken()
      }
    }
  }

  const switchPlanTAM = async (plan_id, treatment_id) => {
    console.log('switching plan tam', plan_id)
    const headers = {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Token ${_tam_token}`,
      }
    }
    const data = {'json_data': plansDWH[plan_id]['modeling_data_dict']}
    return axios.patch(`${TAM_URL}${TAM_UPDATE_PLAN_ENDPOINT(treatment_id)}/`, data, headers)
  }

  const switchPlanTMS = async (plan_id) => {
    console.log('switching plan tms', plan_id)
    const headers = {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${_services_token}`,
      }
    }
    const data = { active: true }
    return axios.patch(`${SERVICES_URL}${SERVICES_PLAN_LIST_ENDPOINT}${plan_id}/`, data, headers)
    }


  const switchPlan = async (plan_id) => {
    console.log('switching plan', plan_id)

    try {
      const service_result = await switchPlanTMS(plan_id)
      if (service_result.status === 200) {
        try {
          const tam_result = await switchPlanTAM(plan_id, _treatment_id)
          if (tam_result.status === 200) {
            setLoading(true)
            await deleteBonesAndGumsTam(_treatment_id)
            setLoading(false)
            fetchPlansTMS()
          }
        } catch (err) {
          try {
            const service_result = await switchPlanTMS(activePlan)
            fetchPlansTMS()
            alert('failed to switch plan on tam')
          } catch (err) {
            console.log(err);
            alert('failed to switch plan on tms')
          }
        }
      } else {
        if (service_result.response.status === 401) {}
        refreshToken()
        alert('failed to switch plan on tms')
      }
  } catch (err) {alert('failed to switch plan on tms')}
  }

  const switchAndCreatePlan = async (plan) => {
    console.log('switching createing plan', plan.id)
    const headers = {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${_services_token}`,
      }
    }
    const data = transformPlan(plan)
    return axios.post(`${SERVICES_URL}${SERVICES_PLAN_LIST_ENDPOINT}/`, data, headers)
      .then(response => console.log(response))
      .then(setLoading(false))
      .then(fetchPlansTMS())
      .catch(err => {
        setError(true)
        if (err.response.status === 401) {
          refreshToken()
          console.log('tms auth headers error');
          alert('Please reload page')
        }
        console.log(err)
      })
  }

  const deleteBonesAndGumsTam = async (plan_id) => {
    console.log('deleting gums and bones', plan_id)
    const headers = {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Token ${_tam_token}`,
      }
    }

    try {
      const del_response = await axios.post(`${TAM_URL}${TAM_DELETE_GUMS_AND_BONES_ENDPOINT(plan_id)}`, {}, headers);

      if (del_response.status === 202)
      {
        const delete_status = await checkTaskStatusTam(plan_id);
        !delete_status && alert('Error: models are not deleted')
      }
    } catch (err) {alert('failed to delete models')}
  }

  const checkTaskStatusTam = async (plan_id) => {
    const headers = {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Token ${_tam_token}`,
      }
    }

    return new Promise(
      (resolve) =>
      {
        const timer = setInterval(
          () => axios
            .get(`${TAM_URL}${TAM_CHECK_TASK_STATUS_ENDPOINT(plan_id)}`, headers)
            .then((res) => res.data.status)
            .then((status) =>
              {
                switch(status)
                {
                  case 'busy':
                    break;
                  case 'completed':
                    clearInterval(timer);
                    resolve(true);
                    break;
                  case 'failed':
                    clearInterval(timer);
                    resolve(false);
                    break;
                  default:
                    clearInterval(timer);
                    resolve(false);
                    break;
                }
              }
            )
          , 1000);
      }
    )
  }

  useEffect(() => {
    fetchPlansTMS()
    return () => { }
  }, [services_token])

  return (
    <>
      <Header />
      <Plans
        plans={plansDWH}
        loading={loading}
        switchPlan={switchPlan}
        switchAndCreatePlan={switchAndCreatePlan}
        is_approved={isApproved}
        treatment_id={_treatment_id}
      />
    </>
  );
}

export default TechnicianPage;