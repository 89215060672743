import React from 'react';
import { Layout } from 'antd';

import CustomButton from '../button/button';

const { Sider } = Layout;


function SideBar() {

    return (
        <>
            <Sider>
                <CustomButton path="/workflow/treatments/new" title="New treatment" />
                <CustomButton path="/workflow/treatments" title="Show treatments" />
            </Sider>
        </>
    );
};

export default SideBar;