import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';

import { setToken as changeToken } from '../../redux/tokenSlice';

import { LOGIN, PASSWORD, ENTER } from '../../constants/en_strings';
import { SERVICES_AUTH_ENDPOINT, TAM_LOGIN_ENDPOINT } from "../../constants/endpoints";
import { SERVICES_URL, TAM_URL } from "../../constants/urls";


async function loginUserTAM(credentials) {

    const headers = {
        username: credentials.username,
        password: credentials.password,
    }

    return axios.post(
        `${TAM_URL}${TAM_LOGIN_ENDPOINT}`, {}, { auth: headers }
    )
        .then(data => { return { success: true, data: data.data } })
        .catch(err => {
            if (err.response.status === 401) {
                return { success: false, data: err, message: err.response.data.detail }
            } else {
                return { success: false }
            }
        })
}

async function loginUserTMS(credentials) {

    const headers = {
        username: credentials.tam_email,
        password: credentials.password,
    }

    return axios.post(
        `${SERVICES_URL}${SERVICES_AUTH_ENDPOINT}`, {}, { auth: headers }
    )
        .then(data => { return { success: true, data: data.data } })
        .catch(err => {
            if (err.response.status === 401) {
                return { success: false, data: 'false creds' }
            } else {
                return { success: false }
            }
        })
}


export default function Login({ setToken }) {

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch()

    const token = searchParams.get("token");
    const service_token = searchParams.get("services_token");
    const treatment_idd = searchParams.get("treatment");
    if (token && service_token) {
        localStorage.setItem('services_access_token', service_token);
        localStorage.setItem('tam_access_token', token);
    }
    dispatch(changeToken({ tam_token: token, services_token: service_token, treatment_id: treatment_idd }))

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/workflow/treatments"

    const errorNotify = (text) => toast.error(text);

    useEffect(() => {
        const services_token = localStorage.getItem('services_access_token')
        if (services_token) {
            navigate("/workflow/treatments")
        }
    }, [])

    const handleSubmit = async e => {
        try {
            e.preventDefault();
            const resultTAM = await loginUserTAM({ username, password });
            const tam_email = resultTAM.data.user.email
            const resultTMS = await loginUserTMS({ tam_email, password });
            if (resultTAM.success && resultTMS.success) {
                setToken(resultTAM, resultTMS);
                dispatch(changeToken({ tam_token: resultTAM.data.token, services_token: resultTMS.data.access }))
                navigate(from, { replace: true });
            }
        } catch (err) {
            errorNotify(err.message);
        }
    }


    return (

        <div className="wrapper">
            <div id="formContent">
                <div className="fadeIn first my-4"></div>
                <form method="post" onSubmit={handleSubmit}>
                <div className="form-group">
                    </div>
                    <div className="form-group">
                        <input id="username" className="form-control fadeIn second" name="username" placeholder={LOGIN} type="text" onChange={e => setUserName(e.target.value)} autoFocus />
                    </div>
                    <div className="form-group">
                        <input id="password" className="form-control fadeIn third" name="password" placeholder={PASSWORD} type="password" onChange={e => setPassword(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <input type="submit" className="fadeIn fourth" value={ENTER} />
                    </div>
                </form>
            </div>
        </div>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
};

