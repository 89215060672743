import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Tooltip } from 'antd';
import { CopyTwoTone } from '@ant-design/icons';


export default function StickyHeadTable({ treatmentList = false, rows, columns, redirectToTreatment, copyTitle }) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    setPage(0)
  }, [rows])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const copyToClickbord = (e, value) => {
    e.stopPropagation();
    navigator.clipboard.writeText(value)
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: "20px" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={Math.random()}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: "700" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {treatmentList && rows ? (
              rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow onClick={(e) => redirectToTreatment !== undefined ? redirectToTreatment(row.treatment_id) : null} hover role="checkbox" tabIndex={-1} key={Math.random()}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={Math.random()} align={column.align} >
                            {column.id === 'treatment_id' || column.id === 'file_id'
                              ? <>
                                {value.split('-')[0]}
                                <Tooltip title={copyTitle}>
                                  <CopyTwoTone style={{ marginLeft: "2px" }} onClick={e => copyToClickbord(e, value)} />
                                </Tooltip>
                              </>
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
            ) : (
              rows.map((row) => {
                return (
                  <TableRow onClick={(e) => redirectToTreatment !== undefined ? redirectToTreatment(row.treatment_id) : null} hover role="checkbox" tabIndex={-1} key={Math.random()}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={Math.random()} align={column.align} >
                          {column.id === 'treatment_id' || column.id === 'file_id'
                            ? <>
                              {value.split('-')[0]}
                              <Tooltip title={copyTitle}>
                                <CopyTwoTone style={{ marginLeft: "2px" }} onClick={e => copyToClickbord(e, value)} />
                              </Tooltip>
                            </>
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {treatmentList ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null
      }
    </Paper>
  );
}