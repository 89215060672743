import React from "react";
import { Layout, Button } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { Toaster } from "react-hot-toast";
import { Route, Routes, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isNull, isUndefined } from "lodash";

import PrivateRoutes from "../components/utils/private_routes";
import Login from "../components/login/login";
import TreatmentsList from "./treatmentsList";
import Treatment from "./treatment/treatment";
import NewTreatment from "./newTreatment";
import SecurePage from "./securePage";
import TechnicianPage from "./technicianPage";
import {
  header_style,
  footer_style,
  logout_button_wrapper_style,
} from "../constants/styles";

import useAccessToken from "../components/utils/useAccessToken";
import { clearToken } from "../redux/tokenSlice";

import "antd/dist/antd.css";
import "../components/login/login.css";

import logoPredict from "../assets/icons/3d_predict.svg";
import logoSmile from "../assets/icons/logo-big.png";
import { deployedRussia } from "../constants/utils";

const mainLogo = deployedRussia() ? logoSmile : logoPredict;

const { Header, Footer } = Layout;

function App() {
  const dispatch = useDispatch();
  const { setToken } = useAccessToken();
  let { services_token } = useSelector((state) => state);
  const storage_services_token = localStorage.getItem("services_access_token");

  const services_token_check = services_token
    ? services_token
    : storage_services_token;
  const auth =
    isNull(services_token_check) ||
    isUndefined(services_token_check) ||
    !services_token_check.length
      ? false
      : true;

  const logout = () => {
    setToken();
    dispatch(clearToken());
  };

  return (
    <div className="App">
      <Toaster toastOptions={{ duration: 5000 }} />
      <Layout className="content" style={{ minHeight: "100vh" }}>
        <Header style={header_style}>
          <div className="logo-wrapper">
            <NavLink to="/workflow/treatments">
              <img
                style={{ width: "120px", marginLeft: "-20px" }}
                src={mainLogo}
                alt=""
              />
            </NavLink>
          </div>
          {auth ? (
            <>
              <div
                className="logout-button-wrapper"
                style={logout_button_wrapper_style}
              >
                <Button
                  className="fadeIn fourth"
                  type="primary"
                  shape="circle"
                  icon={<LogoutOutlined />}
                  size="large"
                  onClick={() => {
                    logout();
                  }}
                />
              </div>
            </>
          ) : null}
        </Header>
        <Layout className="content">
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route
                exact
                path="/workflow/treatments"
                element={<TreatmentsList />}
              />
              <Route
                exact
                path="/treatment/:treatmentId"
                element={<TechnicianPage />}
              />
              <Route
                exact
                path="/workflow/treatments/:treatmentId"
                element={<Treatment />}
              />
              <Route
                exact
                path="/workflow/treatments/new"
                element={<NewTreatment />}
              />
            </Route>
            <Route path="*" element={<Login setToken={setToken} />} />
            <Route path="/secure" element={<SecurePage />} />
            <Route
              exact
              path="/login"
              element={<Login setToken={setToken} />}
            />
          </Routes>
        </Layout>
        <Footer style={footer_style}>
          {deployedRussia() ? "3D Smile" : "3D Predict"}
        </Footer>
      </Layout>
    </div>
  );
}

export default App;
