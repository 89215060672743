import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    tam_token: '',
    services_token: '',
    treatment_id: '',
}

export const tokentSlice = createSlice({
    name: 'tokenSetter',
    initialState,
    reducers: {
        setToken: (state, action) => {
            if (action.payload.tam_token) {state.tam_token = action.payload.tam_token}
            if (action.payload.services_token) {state.services_token = action.payload.services_token}
            if (action.payload.treatment_id) {state.treatment_id = action.payload.treatment_id}
        },
        clearToken: (state) => {
            state.tam_token = ''
            state.services_token = ''
            state.treatment_id = ''
        },
    },
})

export const { setToken, clearToken } = tokentSlice.actions

export default tokentSlice.reducer