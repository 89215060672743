import React from 'react';
import Dropzone from 'react-dropzone';


const S3Uploader = ({ uploadFile, name = "", title, error, fileUploaded, loading, fileExtension }) => {

    return (
        <>
            <Dropzone
                disabled={fileUploaded || loading}
                onDrop={acceptedFiles => uploadFile(acceptedFiles)}
                maxfiles={1}
                accept={`.${fileExtension}`}
                multiple={false}
                style={{
                    height: "100px",
                    border: "2px dotted grey",
                    padding: "20px"
                }}
            >
                {name !== ""
                    ? <p>{name}</p>
                    : <p style={error ? {fontSize: "12px", color: "red"} : {fontSize: "12px"} }>{title}</p>
                }
            </Dropzone>
        </>
    )
}


export default S3Uploader;