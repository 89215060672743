import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Layout, Row, Col, Form, Input, Button, Select } from "antd";
import LinearProgress from "@mui/material/LinearProgress";
import toast from "react-hot-toast";

import { SERVICES_URL } from "../../constants/urls";
import {
  GET_TREATMENTS_TYPES,
  GET_WORKFLOW_TYPES,
  GET_REGIONS,
  CREATE_TREATMENT,
  CT_WORKFLOW_RUN,
  UPLOAD_FILE,
  PUBLIC_FILE,
} from "../../constants/endpoints";
import {
  TREATMENT_TYPES,
  WORKFLOW_TYPES,
  UPLOAD_FILES,
  SUBMIT,
  RUN_SUBMIT,
  FILE_UPLOADED,
  TREATMENT_CREATED,
  REGIONS,
  DROP_SELECT_FILE,
  DROP_SELECT_FILE_AGAIN,
  SEGMENTATION_STARTED,
  FILE_UPLOADING,
  RUNNING,
  UPLOAD_FILE_AFTER_CREATE_TREATMENT,
  UPLOAD_FILE_AFTER_CREATE_TREATMENT_AND_RUN
} from "../../constants/en_strings";
import SideBar from "../../components/sidebar/sidebar";
import S3Uploader from "../../components/inputs/s3_upload";
import { deployedUSA } from "../../constants/utils";
import { generateHeaders } from "../../components/utils/generate_headers";

const { Content } = Layout;

const ct_file_name = "dicom.zip";
const defaultRegion = deployedUSA() ? "3D Predict" : "3D Smile";


function NewTreatment() {
  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [accessToken, setTMSAccessToken] = useState(
    localStorage.getItem("services_access_token")
  );
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [treatmentTypes, setTreatmentTypes] = useState([]);
  const [workflowTypes, setWorkflowTypes] = useState([]);
  const [regions, setRegions] = useState(null);
  const [treatmentId, setTreatmentId] = useState(null);
  const [patientId, setPatientId] = useState(null);
  const [treatmentType, setTreatmentType] = useState(null);
  // THIS IS A TEMPORARY FIX. DO NOT DEPLOY TO PRODUCTION
  const [workflowType, setWorkflowType] = useState("");
  const [region, setRegion] = useState("");
  const [running, setRunning] = useState(false);
  const [run, setRun] = useState(true)

  const errorNotify = (text) => toast.error(text);
  const successNotify = (text) => toast.success(text);

  const auth_headers = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const body = {
    lk_patient_id: patientId,
    treatment_type_id: treatmentType,
    workflow_id: workflowType,
    region: region || defaultRegion,
  };

  const validatePatientId = (id) => {
    const regExpNumber = /^\d+$/;
    if (regExpNumber.test(id) && id > 0) {
      setPatientId(id);
    }
  };

  const getTypes = (url, setTypes, translation) => {
    const types = [];
    return axios
      .get(`${SERVICES_URL}${url}`, auth_headers)
      .then((response) =>
        response.data.map((t, id) =>
          types.push({ value: t.id, label: translation[t.name] })
        )
      )
      .then(() => {
        setTypes(types);
      })
      .then(() => {
        if (translation === WORKFLOW_TYPES) {
          if (types.length === 1) {
            setWorkflowType(types[0].value);
          }
        }
      })
      .catch((err) => {
        errorNotify(err.message);
        setError(err.message);
        if (err.response.status === 401) {
          console.log("tms auth headers error");
          localStorage.removeItem("accessToken");
          return;
        }
      });
  };

  const getRegions = () => {
    const types = [];
    return axios
      .get(`${SERVICES_URL}${GET_REGIONS}`, auth_headers)
      .then((response) =>
        response.data.map((t, id) =>
          types.push({ value: t, label: REGIONS[t] })
        )
      )
      .then(() => {
        setRegions(types)
        setRegion(defaultRegion)
      })
      .catch((err) => {
        errorNotify(err.message);
        setError(err.message);
        if (err.response.status === 401) {
          console.log("tms auth headers error");
          localStorage.removeItem("accessToken");
          return;
        }
      });
  };

  useEffect(() => {
    getTypes(GET_WORKFLOW_TYPES, setWorkflowTypes, WORKFLOW_TYPES);
    getTypes(GET_TREATMENTS_TYPES, setTreatmentTypes, TREATMENT_TYPES);
    getRegions();
  }, []);

  const sendFile = async (id, run) => {
    try {
      setDisableSubmit(true);
      setError(null);
      setLoading(true);
      const upload_files = [];
      upload_files.push({
        name: ct_file_name,
        prefix: "",
        path: "dicom",
        treatment_id: id || treatmentId,
      });
      const getFileUrlRes = await axios.post(
        `${SERVICES_URL}${UPLOAD_FILE}`,
        { files: upload_files },
        auth_headers
      );
      const url = getFileUrlRes.data[0].url;
      const action_id = getFileUrlRes.data[0].id;
      let blob = new Blob([file], { type: file.type });
      const _headers = generateHeaders(action_id, ct_file_name)
      const result = await axios.put(url, blob, { headers: _headers });
      if (result.status === 200) {
        const publicFile = await axios.post(
          `${SERVICES_URL}${PUBLIC_FILE}`,
          { action_id: action_id },
          auth_headers
        );
        if (publicFile.status === 200) {
          setLoading(false);
          if (!run) {
              successNotify(FILE_UPLOADED)
          } else {
              axios.post(`${SERVICES_URL}${CT_WORKFLOW_RUN(id)}`, {}, auth_headers)
              .then(res => {
                  if (res.status === 202) {
                      successNotify(SEGMENTATION_STARTED);
                      setRunning(true);
                  }
              })
              .catch(err => {
                  errorNotify(err.message)
                  setError(err)
              })
            }
          setTimeout(() => {
            const trId = id || treatmentId;
            navigate(`/workflow/treatments/:${trId}`, { state: trId });
          }, 5000);
        }
      }
    } catch (err) {
      setDisableSubmit(false);
      setFile(null);
      setLoading(false);
      setError(err);
    }
  };

  const createTreatment = async (run) => {
    try {
      setDisableSubmit(true);
      const createTreatmentRes = await axios.post(
        `${SERVICES_URL}${CREATE_TREATMENT}`,
        body,
        auth_headers
      );
      const id = createTreatmentRes.data.id;
      setTreatmentId(id);
      sendFile(id, run);
      successNotify(TREATMENT_CREATED);
    } catch (err) {
      errorNotify(err.message);
      setDisableSubmit(false);
      setLoading(false);
      setError(err);
    }
  };

  const uploadFile = async (files) => {
    if (files) {
      setDisableSubmit(false);
      setFile(files[0]);
    }
  };

  const disableBtn = () => {
    return !patientId || !treatmentType || !workflowType || !region || !file || disableSubmit
  }

  return (
    <>
      <SideBar />
      <Content>
        <Form layout="vertical" requiredmark="true">
          <Row style={{ margin: "40px 0" }}>
            <Col span={8} offset={1}>
              <Form.Item required label="Patient Id">
                <Input
                  onChange={(e) => validatePatientId(e.target.value)}
                  value={patientId}
                  placeholder="Type a patient ID"
                />
              </Form.Item>
              <Form.Item required label="Select Workflow Type">
                <Select
                  onChange={(v) => setWorkflowType(v)}
                  options={workflowTypes}
                  defaultValue={workflowTypes}
                  value={workflowTypes}
                  placeholder="Workflow Type"
                ></Select>
              </Form.Item>
              <Form.Item required label="Select Treatment Type">
                <Select
                  onChange={(v) => setTreatmentType(v)}
                  options={treatmentTypes}
                  placeholder="Treatment Type"
                ></Select>
              </Form.Item>
              <Form.Item required label="Select Region">
                <Select
                  defaultValue={defaultRegion}
                  onChange={(v) => setRegion(v)}
                  options={regions}
                  placeholder="Region"
                ></Select>
              </Form.Item>
              <Form.Item required label="Upload a file">
                <p style={{ fontWeight: "700", fontSize: "12px" }}>
                  {UPLOAD_FILES}
                </p>
                <S3Uploader
                  name={file ? file.name : ""}
                  fileExtension={"zip"}
                  uploadFile={uploadFile}
                  accessToken={accessToken}
                  error={error}
                  title={error ? DROP_SELECT_FILE_AGAIN : DROP_SELECT_FILE}
                />
              </Form.Item>
              {loading ? (
                <Form.Item label={FILE_UPLOADING}>
                  <LinearProgress />
                </Form.Item>
              ) : null}
              {error ? (
                <p
                  style={{
                    border: "1px solid red",
                    padding: "10px",
                    color: "red",
                  }}
                >{error.message}
                </p>
              ) : null}
              {running ? <Form.Item label={RUNNING}><LinearProgress /></Form.Item> : null}<Form.Item>
                {error ? (
                  <Row>
                    <Col span={8}>
                      <Button
                        disabled={!file || disableSubmit}
                        onClick={() => sendFile(!run)}
                      >{UPLOAD_FILE_AFTER_CREATE_TREATMENT}
                      </Button>
                    </Col>
                    <Col span={16}>
                      <Button
                        style={{ float: 'right' }}
                        disabled={!file || disableSubmit}
                        onClick={() => sendFile(run)}
                      >{UPLOAD_FILE_AFTER_CREATE_TREATMENT_AND_RUN}
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col span={8}>
                      <Button
                        disabled={disableBtn()}
                        onClick={() => createTreatment(!run)}
                        >
                        {SUBMIT}
                      </Button>
                    </Col>
                    <Col span={16}>
                      <Button
                        style={{ float: 'right' }}
                        disabled={disableBtn()}
                        onClick={() => createTreatment(run)}
                      >
                        {RUN_SUBMIT}
                        </Button>
                    </Col>
                  </Row>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </>
  );
}

export default NewTreatment;
