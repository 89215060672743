import { Button, Modal, Form } from "antd";
import LinearProgress from "@mui/material/LinearProgress";

import S3Uploader from "../inputs/s3_upload";
import {
  DROP_SELECT_FILE,
  UPLOAD,
  UPLOAD_FILE_TYPE,
} from "../../constants/en_strings";

const UploadFileModal = ({
  open,
  onCancel,
  onOk,
  fileName,
  file,
  uploadFile,
  error,
  fileUploaded,
  loading,
  disableSubmit,
  fileExtension,
}) => {
  return (
    <>
      <Modal
        closable={!loading}
        maskClosable={!loading}
        onCancel={onCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
        title="Upload file"
        open={open}
        okButtonProps={{ style: { display: "none" } }}
      >
        <p style={{ fontWeight: "700", fontSize: "12px" }}>
          {UPLOAD_FILE_TYPE(fileName)}
        </p>
        <Form.Item>
          <S3Uploader
            fileUploaded={fileUploaded}
            loading={loading}
            fileExtension={fileExtension}
            name={file ? file.name : ""}
            uploadFile={uploadFile}
            error={error}
            title={DROP_SELECT_FILE}
          />
        </Form.Item>
        {loading ? (
          <Form.Item>
            <LinearProgress />
          </Form.Item>
        ) : null}
        <Button disabled={!file || disableSubmit} onClick={onOk}>
          {UPLOAD}
        </Button>
      </Modal>
    </>
  );
};
export default UploadFileModal;
